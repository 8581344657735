<template>
 <div class="animated fadeIn">
    <div id="snackbar"></div>   
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Create Programming </strong> <small>Form</small>
          </div>

      <b-form v-on:submit.prevent.self="submit">
          <b-row>           
            <b-col sm="3">
              <b-form-group>
                <label for="organization">Organization <span class="text-danger">*</span></label>
                <b-form-select required 
                @change.native="getCinemaList($event.target.value)" 
                v-model="cinemaSchedule.OrganizationID" id="organization"
                  :plain="true"
                  value-field="OrganizationID"
                  text-field="OrganizationName"
                  :options=organizations>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Organization --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

             <b-col sm="3">
              <b-form-group>
                <label for="cinemas">Cinema <span class="text-danger">*</span></label>
                <b-form-select required 
                @change.native="getCinemaScreenList($event.target.value)" 
                v-model="cinemaSchedule.CinemaID" id="cinemas"
                  :plain="true"
                   value-field="CinemaID"
                    text-field="CinemaName"
                  :options=cinemas>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Cinema --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group>
                <label for="screens">Screen <span class="text-danger">*</span></label>
                <b-form-select required 
                @change.native="getCinemaClassList($event.target.value)"
                 v-model="cinemaSchedule.ScreenID" id="screens"
                  :plain="true"
                   value-field="ScreenID"
                  text-field="ScreenName"
                  :options=screens>
                  <template slot="first">
                    <option :value="'0'" disabled>-- Select Screen --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="3">
              <b-form-group>
                <label for="date">From Date <span class="text-danger">*</span></label>
                <b-form-input type="date" required v-model="cinemaSchedule.FromDateSch" id="id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group>
                <label for="date">To Date <span class="text-danger">*</span></label>
                <b-form-input type="date" required v-model="cinemaSchedule.ToDateSch" id="id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group>
                <label for="category">Category <span class="text-danger">*</span></label>
                <b-form-select v-model="cinemaSchedule.CategoryID"
                  :plain="true"
                  value-field="CategoryID"
                  text-field="CategoryTitle"
                  :options=categories>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Category --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>

              <b-col sm="3">
              <b-form-group>
                <label for="movies">Movie <span class="text-danger">*</span></label>
                <b-form-select required v-model="cinemaSchedule.MovieID" id="movies"
                  :plain="true"
                   value-field="MovieID"
                  text-field="Title"
                  @change.native="getFormatData($event.target.value)"                  
                  :options=movies>
                  <template slot="first">
                    <option :value="0" disabled>-- Select Movie --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group>
                <label for="movies">Movie Formats <span class="text-danger">*</span></label>
                <b-form-select required v-model="movieFormat.selectedFormat" id="movies"
                  :plain="true"
                  value-field="value"
                  text-field="text"
                  :options=movieFormat.formatList>
                  <template slot="first">
                    <option :value="'0'" disabled>-- Select Movie Format --</option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group>
                <label for="min_seats">Min Seats <span class="text-danger">*</span></label>
                <!-- <b-form-input disabled type="text" v-model="calculateMinSeats" id="minSeats" placeholder="Min Seats"></b-form-input> -->
                <b-form-input disabled type="text" v-model="cinemaSchedule.MinSeats" id="minSeats" placeholder="Min Seats"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

            <b-col sm="3">
              <b-form-group>
                <label for="status">Status <span class="text-danger">*</span></label>
                <br>
                <c-switch class="mx-1" color="primary" v-model="cinemaSchedule.CinemaScheduleIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />
              </b-form-group>
            </b-col>

            <!-- <b-form-group>
                <label for="schedule">Time <span class="text-danger">*</span></label>
                <b-form-input class="without_ampm" type="time" name="time" id="id"></b-form-input>
              </b-form-group> -->

             <!-- <b-col sm="3">
              <b-form-group>
                <label for="schedule">Schedule <span class="text-danger">*</span></label>
                <b-form-input :type="`time`" required v-for="(index, inx) in autoInput" :key="index +inx" :class="index==1?'':'mt-2'" v-model="cinemaSchedule.CinemaTimings[inx]" id="id" placeholder="Schedule"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <br>
               <span> <i class="fa fa-plus-square-o text-success mt-3 mx-2" @click="addTimeField"></i> </span>
                <span><i class="fa fa-minus-square-o text-danger mt-3 mx-2" @click="removeTimeField"></i></span>
            </b-col>    -->
            
          
          
          <!-- <b-row v-if="enableClass">
            <b-col sm="3">
              <b-form-group v-for="(c, cindex) in classes" :key="cindex">
               <b-form-input type="text" disabled :value="c.SeatClassName"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group v-for="(c, cindex) in classes" :key="cindex">
                <b-form-input placeholder="Price"  type="number" step="any" v-model="cinemaSchedule.classValues[cindex]"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row> -->
          <!-- <b-row v-if="enableClass">
             <b-col sm="2">
               <h1 @click="tempClick()">click Here</h1>
              <br>
               <span><i class="fa fa-plus-square-o text-success mt-3 mx-2" @click="addExtraTimeField"></i> </span>
            </b-col>
          </b-row> -->
          
          <b-row v-if="enableClass" v-for="(itemList, index) in priceAndTimeList" :key="index">
            <b-col sm="3" v-for="(item, _index) in itemList.list" :key="_index">
              <b-form-group>
                <label v-if="index == 0" for="schedule">{{ item.label }} <span class="text-danger">*</span></label>
                <b-form-input :type="item.label == 'Schedule' ? 'time' : 'number'" v-model="item.value" required id="id" placeholder="Price"></b-form-input>
              </b-form-group>              
            </b-col>
            <b-col v-if="priceAndTimeList.length > 0" sm="2">
              <br v-if="index == 0"/>
                <span v-if="index == 0"><i class="fa fa-plus-square-o text-success mt-3 mx-2" @click="addExtraTimeField"></i> </span>              
                <span v-if="index != 0"><i :class="index == 0 ? 'fa fa-minus-square-o text-danger mt-3 mx-2': 'fa fa-minus-square-o text-danger mt-2 mx-2'" @click="removeExtraTimeField(index)"></i></span>
            </b-col>
          </b-row>
        
          <b-row>
              <b-col sm="6">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
              </b-col>

              <b-col sm="6">
                  <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
     <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>

  </div>
</template>

<script>
import OrganizationService from "@/services/OrganizationService";
import CinemaService from "@/services/CinemaService";
import ScreenService from "@/services/ScreenService";
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";

export default {
  name: "CreateProgramming",
  components: {
    cSwitch,
    Event
  },
  data() {
    return {
      //Saif
      movieFormat: {
        selectedFormat: "0",
        formatList: [
          {
            text: "Select Format",
            value: "0"
          }
        ]
      },
      timeAndPriceList: {
        columns: ["Schedule", "G", "S"],
        data: [
          {
            Schedule: ""
          }
        ],
        options: {
          // headings: {
          //   Schedule: "Schedule"
          // }
        }
      },
      columnsList: ["Schedule", "G", "S"],

      // New
      classesData: [],
      priceAndTimeList: [],
      //Saif

      cinemaSchedule: {
        CinemaScheduleID: null,
        OrganizationID: 0,
        CinemaID: 0,
        ScreenID: 0,
        MovieID: 0,
        CategoryID: 0,
        FromDateSch: "",
        ToDateSch: "",
        CinemaTimings: [],
        classValues: [],
        MinSeats: "",
        CinemaScheduleIsActive: true
        // Flag: 1
      },
      minSeatsHit: false,
      enableClass: false,
      classes: [],
      autoInput: 1,
      organizations: [],
      cinemas: [],
      screens: [],
      movies: [],
      categories: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    var timings = [];
    this.getOrganizationList();
    this.getMovies();
    this.getCategories();
    const { CinemaScheduleID } = this.$route.query;
    if (CinemaScheduleID) {
      this.getCinemaScheduleListID(CinemaScheduleID);
    }
  },
  methods: {
    getCinemaScheduleListID: async function(CinemaScheduleID) {
      // try {
      //   let res = await CinemaService.getCinemaScheduleListID(CinemaScheduleID);
      //   let cinemaScheduleTimings = res.data[0].CinemaTimings;
      //   let seatClasses = res.data[0].SeatClassPrice;
      //   this.cinemaSchedule = res.data[0];
      //   this.getCinemaList(this.cinemaSchedule.OrganizationID);
      //   this.getCinemaScreenList(this.cinemaSchedule.CinemaID);
      //   await this.setProgrammingDetails(cinemaScheduleTimings, seatClasses);
      // } catch (error) {
      //   this.showMessageSnackbar(
      //     "Oops! Something went wrong. kindly contact your administrator"
      //   );
      // }
      let payload = {
        CinemaScheduleID
      };

      CinemaService.getCinemaScheduleListID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaScheduleList.length > 0) {
            let cinemaScheduleTimings =
              data.CinemaScheduleList[0].CinemaTimings;
            let seatClasses = data.CinemaScheduleList[0].SeatClassPrice;
            this.cinemaSchedule = data.CinemaScheduleList[0];
            this.getCinemaList(this.cinemaSchedule.OrganizationID);
            this.getCinemaScreenList(this.cinemaSchedule.CinemaID);
            this.setProgrammingDetails(cinemaScheduleTimings, seatClasses);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    tempClick: function() {
      let array = [];
      this.priceAndTimeList.map(x => {
        let obj = {};
        // let temp = x.list.filter(z => z.label == "Schedule");
        // obj["Time"] = temp.length > 0 ? temp[0].value : "";
        obj["SeatType"] = [];
        x.list.map(y => {
          if (y.label == "Schedule") {
            obj["Time"] = y.value;
          } else if (y.label !== "Schedule") {
            let ob = {};
            ob["ID"] = y.SeatClassID;
            ob["Price"] = y.value;
            ob["Name"] = y.SeatClassName;
            obj["SeatType"].push(ob);
          }
        });
        array.push(obj);
      });
    },
    removeExtraTimeField: function(index) {
      this.priceAndTimeList.splice(index, 1);
    },
    addExtraTimeField: function() {
      let array = [
        {
          label: "Schedule",
          value: "",
          ScreenID: null,
          SeatClassID: null,
          SeatClassName: null,
          SeatClassPrice: null
        }
      ];
      this.classesData.map(x => {
        let obj = {};
        Object.assign(obj, x);
        obj["label"] = x.SeatClassName;
        obj["value"] = "";
        array.push(obj);
      });
      this.priceAndTimeList.push({ list: array });
    },
    getMinimumSeats: function() {
      console.log(
        "object",
        this.cinemaSchedule.ScreenID,
        this.cinemaSchedule.MovieID
      );
      if (
        this.cinemaSchedule.ScreenID != 0 &&
        this.cinemaSchedule.ScreenID != "0" &&
        this.cinemaSchedule.MovieID != 0 &&
        this.cinemaSchedule.MovieID != "0"
      ) {
        console.log("Inside If");
        // if (this.minSeatsHit == false) {
        let body = {
          MovieID: this.cinemaSchedule.MovieID,
          ScreenID: this.cinemaSchedule.ScreenID
        };
        CinemaService.getMinSeats(body)
          .then(res => {
            if (res.data.Status) {
              this.cinemaSchedule.MinSeats = res.data.RequiredMinSeats;
              this.minSeatsHit = true;
            }
          })
          .catch(error => {
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    resetForm: function() {
      this.cinemaSchedule = {
        CinemaScheduleID: null,
        OrganizationID: 0,
        CinemaID: 0,
        ScreenID: 0,
        MovieID: 0,
        FromDateSch: "",
        ToDateSch: "",
        CinemaTimings: [],
        classValues: [],
        CinemaScheduleIsActive: true
      };
      this.cinemas = [];
      this.screens = [];
      this.autoInput = 1;
      this.classes = [];

      window.location.replace(
        // window.location.origin + "/" + window.location.hash.split("?")[0]
        window.location.href.split("?")[0]
      );
    },
    setProgrammingDetails: function(programmingObj, seatClasses) {
      this.autoInput = 0;
      this.cinemaSchedule.CinemaTimings = [];

      if (programmingObj.indexOf(",") >= 0) {
        let timings = programmingObj.split(",");
        timings.map(ct => {
          this.autoInput++;
          this.cinemaSchedule.CinemaTimings.push(ct);
        });
      } else {
        this.autoInput++;
        this.cinemaSchedule.CinemaTimings.push(programmingObj);
      }
      if (seatClasses.length > 0) {
        this.enableClass = true;
        this.classes = [];
        this.cinemaSchedule.classValues = [];
      }
      seatClasses.map(sC => {
        this.classes.push({ SeatClassName: sC.Name, SeatClassID: sC.ID });
        this.cinemaSchedule.classValues.push(sC.Price);
      });
    },
    getMovies: function() {
      // let res = await MovieService.getMasterMovies();
      this.movies = [];
      MovieService.getMasterMovies()
        .then(response => {
          const { data } = response;
          if (data.Status && data.MovieList.length > 0) {
            this.movies = data.MovieList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getFormatData: function(MovieID) {
      this.movieFormat.formatList = [
        // {
        //   text: "Select Format",
        //   value: "0"
        // }
      ];
      let payload = {
        MovieID
      };
      this.movieFormat.selectedFormat = "0";
      this.$nextTick(() => {
        this.getMinimumSeats();
      });
      // let res = await MovieService.getFormatData(payload);
      MovieService.getFormatData(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.MovieFormatList.length > 0) {
            //     this.movieFormat.formatList = this.movieFormat.formatList.concat(
            //   res.data.List
            // );
            this.movieFormat.formatList = data.MovieFormatList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getOrganizationList: function() {
      this.organizations = [];
      // let res = await OrganizationService.getOrgsDDL();
      OrganizationService.getOrganizationDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.organizations = data.OrganizationList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCategories: function() {
      this.categories = [];
      // let res = await MasterService.getCategoriesDDL();
      MasterService.getCategoriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CategoryList.length > 0) {
            this.categories = data.CategoryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemaList: function(OrgID) {
      this.cinemas = [];
      this.priceAndTimeList = [];
      this.enableClass = false;
      this.cinemaSchedule.CinemaID = 0;
      this.cinemaSchedule.ScreenID = "0";
      // let res = await CinemaService.getCinemasDDL(e);
      let payload = {
        OrgID
      };
      CinemaService.getCinemasDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.cinemas = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemaScreenList: function(CinemaID) {
      this.priceAndTimeList = [];
      this.cinemaSchedule.ScreenID = "0";

      this.enableClass = false;
      let payload = {
        CinemaID
      };
      this.screens = [];
      // let res = await ScreenService.getScreensDDL(e);
      ScreenService.getScreensDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.ScreenList.length > 0) {
            this.screens = data.ScreenList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemaClassList: function(ScreenID) {
      this.priceAndTimeList = [];
      // let res = await CinemaService.getCinemaClasses(ScreenID);
      let payload = {
        ScreenID
      };
      this.$nextTick(() => {
        this.getMinimumSeats();
      });
      CinemaService.getCinemaClasses(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaClassList.length > 0) {
            this.classes = [];
            this.classesData = data.CinemaClassList;
            data.CinemaClassList.map(x => {
              let obj = {};
              Object.assign(obj, x);
              obj["SeatClassPrice"] = 0;
              this.classes.push(obj);
            });
            let array = [
              {
                label: "Schedule",
                value: "",
                ScreenID: null,
                SeatClassID: null,
                SeatClassName: null,
                SeatClassPrice: null
              }
            ];
            data.CinemaClassList.map(x => {
              let obj = {};
              Object.assign(obj, x);
              obj["label"] = x.SeatClassName;
              obj["value"] = "";
              array.push(obj);
            });
            this.priceAndTimeList.push({ list: array });

            this.enableClass = true;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    addTimeField: function() {
      this.autoInput++;
    },
    removeTimeField: function() {
      if (this.autoInput > 1) {
        this.autoInput--;
        this.cinemaSchedule.CinemaTimings.splice(
          this.cinemaSchedule.CinemaTimings.length - 1,
          1
        );
      }
    },
    validation: function() {
      if (this.cinemaSchedule.OrganizationID == 0) {
        this.showMessageSnackbar("Please select the Organization.");
        return false;
      } else if (this.cinemaSchedule.CinemaID == 0) {
        this.showMessageSnackbar("Please select the Cinema.");
        return false;
      } else if (this.cinemaSchedule.ScreenID == "0") {
        this.showMessageSnackbar("Please select the Screen.");
        return false;
      } else if (this.cinemaSchedule.FromDateSch == "") {
        this.showMessageSnackbar("From Date Cannot be empty.");
        return false;
      } else if (this.cinemaSchedule.ToDateSch == "") {
        this.showMessageSnackbar("To Date Cannot be empty.");
        return false;
      } else if (this.cinemaSchedule.CategoryID == 0) {
        this.showMessageSnackbar("Please select the Category.");
        return false;
      } else if (this.cinemaSchedule.MovieID == 0) {
        this.showMessageSnackbar("Please select the Movie.");
        return false;
      } else if (this.movieFormat.selectedFormat == "0") {
        this.showMessageSnackbar("Please select the Movie Format.");
        return false;
      } else {
        return true;
      }
    },

    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      let obj = {};
      // let string = "";
      // let seatString = "";
      let listArray = [];

      this.priceAndTimeList.map(x => {
        let priceobj = {};
        priceobj["SeatType"] = [];
        x.list.map(y => {
          if (y.label == "Schedule") {
            priceobj["Time"] = y.value;
          } else if (y.label !== "Schedule") {
            let ob = {};
            ob["ID"] = y.SeatClassID;
            ob["Price"] = y.value;
            // ob["Name"] = y.SeatClassName;
            priceobj["SeatType"].push(ob);
          }
        });
        listArray.push(priceobj);
      });
      obj["CinemaScheduleID"] = this.cinemaSchedule.CinemaScheduleID;
      obj["OrganizationID"] = this.cinemaSchedule.OrganizationID;
      obj["CinemaID"] = this.cinemaSchedule.CinemaID;
      obj["ScreenID"] = this.cinemaSchedule.ScreenID;
      obj["MovieID"] = this.cinemaSchedule.MovieID;
      obj["CategoryID"] = this.cinemaSchedule.CategoryID;
      obj["FromDateSch"] = this.cinemaSchedule.FromDateSch;
      obj["ToDateSch"] = this.cinemaSchedule.ToDateSch;
      obj["MinSeats"] = this.cinemaSchedule.MinSeats;
      obj[
        "CinemaScheduleIsActive"
      ] = this.cinemaSchedule.CinemaScheduleIsActive;

      obj["SeatsWithTimeNPrice"] = listArray;
      obj["FormatAndLanguage"] = this.movieFormat.selectedFormat;
      // let res = await CinemaService.addCinemaSchedule(obj);

      // Change The Below Whenever free
      if (obj["CinemaScheduleID"] && obj["CinemaScheduleID"] != null) {
        obj["Flag"] = 2;
      } else {
        obj["CinemaScheduleID"] = undefined;
        obj["Flag"] = 1;
      }
      CinemaService.addCinemaSchedule(obj)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.movieFormat.formatList = [];
            // this.movieFormat.formatList.push({
            //   text: "Select Organization",
            //   value: "0"
            // });
            this.movieFormat.selectedFormat = "0";
            this.cinemaSchedule = {
              CinemaScheduleID: null,
              OrganizationID: 0,
              CinemaID: 0,
              ScreenID: 0,
              MovieID: 0,
              FromDateSch: "",
              ToDateSch: "",
              CinemaTimings: [],
              classValues: [],
              CinemaScheduleIsActive: true,
              CategoryID: 0
            };
            this.priceAndTimeList = [];
            this.cinemas = [];
            this.screens = [];
            this.autoInput = 1;
            this.classes = [];
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  },
  computed: {}
};
</script>

<style>
.fa-plus-square-o,
.fa-minus-square-o {
  font-size: 20px;
  cursor: pointer;
}
</style>
<style scoped>
/* .without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
} */
</style>

